<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <div>
    <div class="d-flex justify-end">
      <v-btn color="info" text @click="filterLogs()">
        <v-icon small>mdi-reload</v-icon> Reload
      </v-btn>
      <v-btn color="error" text @click="resetFilter('all')">
        <v-icon small>mdi-close</v-icon> Clear Filters
      </v-btn>
    </div>

    <v-card class="mt-1">
      <v-toolbar dark color="primary">
        <div class="text-h6">Logs</div>
        <v-divider class="mx-2" vertical />

        <!--   <div class="mt-2">
        <v-select
          outlined
          dense
          :items="['LOG', 'Error']"
          v-model="query"
          clearable
        ></v-select>
      </div> -->

        <div class="mt-4">
          <v-select class="mt-2" :items="['LOG', 'ERROR', 'WARNING']" v-model="bodyreq.type" outlined label="Log Type"
            dense clearable @change="filterLogs" @click:clear="resetFilter('types')"></v-select>
        </div>
        <div class="mt-4 ml-1">
          <v-select class="mt-2" :items="filsecondsstart" v-model="executionTimeStart" outlined
            label="Execution Time Start" dense item-text="label" item-value="value" clearable @change="filterLogs"
            @click:clear="resetFilter('timeStart')"></v-select>
        </div>
        <div class="mt-4 ml-1">
          <v-select class="mt-2" :items="filsecondsend" v-model="executionTimeEnd" outlined label="Execution Time End"
            dense item-text="label" item-value="value" clearable @change="filterLogs"
            @click:clear="resetFilter('timeEnd')"></v-select>
        </div>
        <div class="mt-2 mx-1">
          <v-text-field outlined prepend-inner-icon="mdi-magnify" class="mt-4" dense clearable v-model="query"
            label="Search"></v-text-field>
        </div>

        <v-autocomplete class="mx-1" label="Filter by Employees" :items="workers" v-model="bodyreq.employeeUuid"
          item-text="fullname" item-value="uuid" style="max-width: 300px" prepend-inner-icon="mdi-account-search"
          clearable @change="filterLogs" @click:clear="resetFilter('employees')" hide-details outlined dense>
          <template v-slot:item="data">
            <template>
              <v-list-item-content>
                <v-list-item-title v-html="data.item.fullname"></v-list-item-title>
                <v-list-item-subtitle v-html="data.item.rol"></v-list-item-subtitle>
                <v-list-item-subtitle v-html="data.item.phone"></v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </template>
        </v-autocomplete>
        <v-layout class="ma-4">
          <v-row class="mt-3" no-gutters>
            <v-col class="mt-3">
              <ma-date-picker :datepickerProps="{
                'no-title': true,
                max: maxDate,
              }" v-model="dateFrom" label="From" past />
            </v-col>
            <v-col class="ml-2 mt-3">
              <ma-date-picker :datepickerProps="{
                'no-title': true,
                max: maxDate,
              }" v-model="dateTo" label="To" />
            </v-col>
          </v-row>
        </v-layout>
      </v-toolbar>
      <v-layout class="pa-4" row>
        <v-layout column>
          <div class="mr-2 text-capitalize">
            {{ filterRanges.replace("-", " ") }}
          </div>

          <v-layout column class="text-body-1 text--secondary">
            <div>
              From:
              <span class="text-caption">{{
                prettyDate(bodyreq.dates.date1)
              }}</span>
            </div>
            <div>
              To:
              <span class="text-caption">{{
                prettyDate(bodyreq.dates.date2)
              }}</span>
            </div>
          </v-layout>
        </v-layout>
      </v-layout>

      <v-divider></v-divider>

      <template>
        <v-data-table item-key="uuid" :footer-props="footerProps" :server-items-length="totallogs"
          :options.sync="options" :headers="headers" :loading="loading" :items="systemLogs" class="elevation-1">
          <template v-slot:[`item.createdAt`]="{ item }">
            {{ prettyDate(item.createAt) }}
          </template>
          <template v-slot:[`item.executionTimeMs`]="{ item }">
            {{ prettySecond(item.executionTimeMs) }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon color="info" v-on="on" v-bind="attrs"
                  @click="gotoLog(item)"><v-icon>mdi-eye</v-icon></v-btn>
              </template>
              <span>View Details</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </template>
    </v-card>
  </div>

</template>
<script>
import moment from "moment";

import MaDatePicker from "@/components/MaDatePicker/MaDatePicker.vue";
import { mapActions, mapState } from "vuex";
export default {
  components: { MaDatePicker },
  data() {
    return {
      loading: false,
      filterRanges: "this-week",
      filterEmployees: "",
      dateFrom: "",
      expanded: [],
      footerProps: { "items-per-page-options": [15, 50, 100, 500] },
      options: {},
      maxDate: new Date().toISOString(),
      dateTo: "",
      query: null,
      executionTimeStart:0,
      executionTimeEnd:0,
      bodyreq: {
        query: null,
        type: null,
        employeeUuid: null,
        dates: { date1: "", date2: "" },
        range: {
          limit: 15,
          offset: 0,
        },
        durationRange:{
          start:0,
          end:0  
        }
      },
      headers: [
        {
          text: "Type",
          align: "start",
          sortable: false,
          value: "type",
        },
        {
          text: "Path",
          sortable: false,
          value: "path",
        },
        {
          text: "Create At",
          sortable: false,
          value: "createdAt",
        },
        {
          text: "Execution Time",
          align:"end",
          sortable: false,
          value: "executionTimeMs",
        },
        { text: "", value: "actions", sortable: false },
      ],

      filsecondsstart:[
        {label:'1 secs',value:1000},
        {label:'2 secs',value:2000},
        {label:'5 secs',value:5000},
        {label:'10 secs',value:10000},
        {label:'20 secs',value:20000},
        {label:'30 secs',value:30000},
        {label:'45 secs',value:45000},
        {label:'60 secs',value:60000},
      ],

      filsecondsend:[
        {label:'2 secs',value:2000},
        {label:'5 secs',value:5000},
        {label:'10 secs',value:10000},
        {label:'20 secs',value:20000},
        {label:'30 secs',value:30000},
        {label:'45 secs',value:45000},
        {label:'60 secs',value:60000},
        {label:'120 secs',value:120000},
      ]
    };
  },
  computed: {
    ...mapState("crmEmployeeModule", ["workers"]),
    ...mapState("crmConfigurationsModule", ["systemLogs", "totallogs"]),
    intervalWeek() {
      const from = moment().startOf("week").utc().toISOString();
      const to = moment().utc().toISOString();

      return {
        date1: from,
        date2: to,
      };
    },
  },
  watch: {
    dateFrom(val) {
      if (val !== null && this.dateTo !== null && this.dateTo !== "") {
        this.filterRanges = "custom-select";
        this.filterLogs();
      }
    },
    dateTo(val) {
      if (val !== null && this.dateFrom !== null && this.dateFrom !== "") {
        this.filterRanges = "custom-select";
        this.filterLogs();
      }
    },
    options(val) {
      if (val != {}) {
        this.filterLogs();
      }
    },
    query(val) {
      if (val == null || val.length == 0) {
        this.bodyreq.query = null;
        this.filterLogs();
      } else if (val != null && val.length >= 3) {
        this.bodyreq.query = val;
        this.filterLogs();
      }
    },
  },
  methods: {
    ...mapActions("crmEmployeeModule", ["actGetEmployees"]),
    ...mapActions("crmConfigurationsModule", ["actFilterLogs"]),
    async setIntervar(interval) {
      this.filterRanges = interval;
    },
    prettySecond(date){
      let second = 0
      if (date) {
        second =  date/1000
        second = Math.trunc(second)
      } 
      return second==1? second + ' sec':second!=1&&second!=0?second + ' secs':0
    },

    prettyDate(date) {
      if (date === null || date === "") return "";
      /*  if (this.filterRanges == "custom-select") {
        return moment(date).format("dddd D MMMM YYYY, h:mm:ss a");
      } */

      return moment(date).format("dddd D MMMM YYYY, h:mm:ss a");
    },

    async filterLogs() {
      const { page, itemsPerPage } = this.options;
      const itmper = itemsPerPage != -1 ? itemsPerPage : 15;
      let body = {
        dateRange: this.bodyreq.dates,
        employeeUuid: this.bodyreq.employeeUuid,
        range: this.bodyreq.range,
        
      };
      body.range = {
        limit: itmper,
        offset: (page - 1) * itmper,
      };
      if (this.bodyreq.query != null) {
        body = { ...body, query: this.bodyreq.query };
      }
      if (this.bodyreq.type != null) {
        body = { ...body, type: this.bodyreq.type };
      }

      if ((this.executionTimeStart!=0&&this.executionTimeStart!=null&&this.executionTimeStart!=undefined)||(this.executionTimeEnd!=0&&this.executionTimeEnd!=null&&this.executionTimeEnd!=undefined)) {
        this.bodyreq.durationRange.start = this.executionTimeStart!=0&&this.executionTimeStart!=null?this.executionTimeStart:1000;
        this.bodyreq.durationRange.end = this.executionTimeEnd!=0&&this.executionTimeEnd!=null?this.executionTimeEnd:120000;
        body = {...body,durationRange:this.bodyreq.durationRange }
      }

      switch (this.filterRanges) {
        case "this-week":
          body.dateRange = {
            date1: this.intervalWeek.date1,
            date2: this.intervalWeek.date2,
          };
          break;

        case "custom-select":
          body.dateRange = {
            date1: moment(this.dateFrom).startOf("day").utc().toISOString(),
            date2: moment(this.dateTo).endOf("day").utc().toISOString(),
          };
          break;
      }
      this.bodyreq.dates = body.dateRange;
      this.expanded = [];
      body = this.cleanNull(body);
      this.loading = true;
      await this.actFilterLogs(body);
      this.loading = false;
    },

    cleanNull(obj) {
      for (var propName in obj) {
        if (
          obj[propName] === null ||
          obj[propName] === undefined ||
          obj[propName] === ""
        ) {
          delete obj[propName];
        }
      }
      return obj;
    },

    resetFilter(value) {
      switch (value) {
        case "custom-select":
          this.filterRanges = "this-week";
          this.dateFrom = "";
          this.dateTo = "";
          this.filterLogs();
          break;
        case "employees":
          this.filterEmployees = "";
          this.bodyreq.employeeUuid = null;
          this.filterLogs();
          break;
        case "types":
          this.bodyreq.type = null;
          this.filterLogs();
          break;
        case "timeStart":
          this.bodyreq.durationRange = {start:0,end:0};
          this.executionTimeStart=0;
         
          this.filterLogs();
          break;
        case "timeEnd":
          this.bodyreq.durationRange = {start:0,end:0};
          
          this.executionTimeEnd=0;
          this.filterLogs();
          break;
        case "all":
          this.filterRanges = "this-week";
          this.dateFrom = "";
          this.dateTo = "";
          this.filterEmployees = "";
          this.bodyreq.employeeUuid = null;
          this.bodyreq.type = null;
          this.bodyreq.durationRange = {start:0,end:0};
          this.bodyreq.durationRange = {start:0,end:0};
          this.executionTimeStart=0;
          this.executionTimeEnd=0;
          this.filterLogs();
          break;
        default:
          this.filterEmployees = "";

          this.filterRanges = "this-week";
          this.bodyreq = {
            employeeUuid: null,
            dates: { date1: "", date2: "" },
          };
          this.filterLogs();
          break;
      }
    },
    gotoLog(item) {
      this.$router.push(`/logs/log/${item.uuid}`);
    },
  },
  async mounted() {
    this.actGetEmployees();
    await this.setIntervar(this.filterRanges);
  },
};
</script>
